/* eslint-disable jsdoc/require-param, jsdoc/require-returns */
/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
import { Maybe, Nullable } from '@chroma-x/common/core/util';
import { AbstractRequestModelConverter } from '@chroma-x/frontend/core/api-integration';
import {
	BaseObjectiveMutateModel,
	ObjectiveProgressMode,
	ObjectiveScope,
	ObjectiveTimeframe
} from '@chroma-x/frontend/domain/okr/domain-model';

import { BaseObjectiveMutateDto } from '../../../../../dto';

export class BaseObjectiveMutateRequestModelConverter extends AbstractRequestModelConverter<
	BaseObjectiveMutateModel,
	BaseObjectiveMutateDto
> {
	public toDto(model: BaseObjectiveMutateModel): BaseObjectiveMutateDto {
		return {
			title: model.title satisfies Maybe<string>,
			descriptionString: model.descriptionString satisfies Maybe<Nullable<string>>,
			scope: model.scope satisfies Maybe<ObjectiveScope>,
			timeframe: model.timeframe satisfies Maybe<ObjectiveTimeframe>,
			progressMode: model.progressMode satisfies Maybe<ObjectiveProgressMode>
		};
	}
}
