/* eslint-disable jsdoc/require-param, jsdoc/require-returns */
/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
import { ManualProgressObjectiveCreateModel, ObjectiveProgressMode } from '@chroma-x/frontend/domain/okr/domain-model';

import { BaseObjectiveCreateRequestModelConverter } from './base-objective-create-request-model-converter';
import { ManualProgressObjectiveCreateDto } from '../../../../../dto';

export class ManualProgressObjectiveCreateRequestModelConverter extends BaseObjectiveCreateRequestModelConverter {
	public toDto(model: ManualProgressObjectiveCreateModel): ManualProgressObjectiveCreateDto {
		return {
			...super.toDto(model),
			progressMode: model.progressMode satisfies ObjectiveProgressMode.MANUAL
		};
	}
}
