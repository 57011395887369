/* eslint-disable jsdoc/require-param, jsdoc/require-returns */
/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
export enum ObjectiveServicePermission {
	OKR_OBJECTIVE_LIST = 'okr:objective:list',
	OKR_OBJECTIVE_READ = 'okr:objective:read',
	OKR_OBJECTIVE_CREATE = 'okr:objective:create',
	OKR_OBJECTIVE_MUTATE = 'okr:objective:mutate',
	OKR_OBJECTIVE_DELETE = 'okr:objective:delete',
	OKR_OBJECTIVE_SET_STATUS = 'okr:objective:set-status',
	OKR_OBJECTIVE_SET_PROGRESS = 'okr:objective:set-progress',
	OKR_OBJECTIVE_SET_RESPONSIBLES = 'okr:objective:set-responsibles',
	OKR_OBJECTIVE_ADD_FOLLOWER = 'okr:objective:add-follower',
	OKR_OBJECTIVE_REMOVE_FOLLOWER = 'okr:objective:remove-follower',
	OKR_OBJECTIVE_SET_FOLLOWERS = 'okr:objective:set-followers'
}

export const objectiveServicePermissions = [
	ObjectiveServicePermission.OKR_OBJECTIVE_LIST,
	ObjectiveServicePermission.OKR_OBJECTIVE_READ,
	ObjectiveServicePermission.OKR_OBJECTIVE_CREATE,
	ObjectiveServicePermission.OKR_OBJECTIVE_MUTATE,
	ObjectiveServicePermission.OKR_OBJECTIVE_DELETE,
	ObjectiveServicePermission.OKR_OBJECTIVE_SET_STATUS,
	ObjectiveServicePermission.OKR_OBJECTIVE_SET_PROGRESS,
	ObjectiveServicePermission.OKR_OBJECTIVE_SET_RESPONSIBLES,
	ObjectiveServicePermission.OKR_OBJECTIVE_ADD_FOLLOWER,
	ObjectiveServicePermission.OKR_OBJECTIVE_REMOVE_FOLLOWER,
	ObjectiveServicePermission.OKR_OBJECTIVE_SET_FOLLOWERS
];
