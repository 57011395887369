/* eslint-disable jsdoc/require-param, jsdoc/require-returns */
/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
import {
	CollectionPaginationResponse,
	DeleteResponse,
	FilterCriteria,
	HypermediaEntityResponse,
	HypermediaResponse,
	ModelPrimaryKey,
	PaginatedCollectionResponse,
	SortCriteria,
	extractHypermediaResponse,
	extractPaginationResponse
} from '@chroma-x/common/core/api-integration';
import { AppError } from '@chroma-x/common/core/error';
import { Optional } from '@chroma-x/common/core/util';
import { JsonRestRequest, UrlPathParameter, UrlQueryParameter, buildApiEndpointUrl } from '@chroma-x/frontend/core/api-integration';
import { AccessTokenLoader, AccessTokenLoaderClosure, AuthHandler } from '@chroma-x/frontend/core/auth-handler';
import {
	FollowerRequestModel,
	FollowersRequestModel,
	ObjectiveCreateModel,
	ObjectiveModel,
	ObjectiveMutateModel,
	ProgressRequestModel,
	ResponsiblesRequestModel,
	StatusRequestModel
} from '@chroma-x/frontend/domain/okr/domain-model';

import { ObjectiveDto } from '../dto';
import {
	FollowerRequestRequestModelConverter,
	FollowersRequestRequestModelConverter,
	ObjectiveCreateRequestModelConverter,
	ObjectiveMutateRequestModelConverter,
	ProgressRequestRequestModelConverter,
	ResponsiblesRequestRequestModelConverter,
	StatusRequestRequestModelConverter
} from '../model-converter/request';
import { ObjectiveResponseModelConverter } from '../model-converter/response';

export class ObjectiveServiceApiClient {
	private readonly apiBaseUrl: string;
	private readonly accessTokenLoader: AccessTokenLoaderClosure;

	public constructor() {
		this.apiBaseUrl = new Optional(process.env.NX_PUBLIC_OKR_API_BASE_URL).getOrThrow(
			new AppError('ObjectiveService API base URL unavailable')
		);
		this.accessTokenLoader = AccessTokenLoader.createLoader(AuthHandler.get());
	}

	public async fetchPage(
		page = 1,
		size = 50,
		sort?: SortCriteria<ObjectiveModel>,
		filter?: FilterCriteria<ObjectiveModel>
	): Promise<PaginatedCollectionResponse<ObjectiveModel>> {
		const url = buildApiEndpointUrl(
			this.apiBaseUrl,
			'okr/objective/objectives',
			new Map<string, UrlPathParameter>([
				['page', page],
				['size', size]
			]),
			new Map<string, UrlQueryParameter>([
				['sort', sort],
				['filter', filter]
			])
		);
		const jwt = await this.accessTokenLoader();
		const parsedResponse = await new JsonRestRequest(jwt).get(url);
		const dto = parsedResponse.body as Array<ObjectiveDto>;
		const items = new ObjectiveResponseModelConverter().toArrayOfModel(dto);
		return { items, ...extractPaginationResponse(parsedResponse.headers) };
	}

	public async headPage(page = 1, size = 50, filter?: FilterCriteria<ObjectiveModel>): Promise<CollectionPaginationResponse> {
		const url = buildApiEndpointUrl(
			this.apiBaseUrl,
			'okr/objective/objectives',
			new Map<string, UrlPathParameter>([
				['page', page],
				['size', size]
			]),
			new Map<string, UrlQueryParameter>([['filter', filter]])
		);
		const jwt = await this.accessTokenLoader();
		const parsedResponse = await new JsonRestRequest(jwt).head(url);
		return extractPaginationResponse(parsedResponse.headers);
	}

	public async fetch(id: ModelPrimaryKey): Promise<HypermediaEntityResponse<ObjectiveModel>> {
		const url = buildApiEndpointUrl(
			this.apiBaseUrl,
			'okr/objective/objective',
			new Map<string, UrlPathParameter>([['id', id]]),
			new Map<string, UrlQueryParameter>([])
		);
		const jwt = await this.accessTokenLoader();
		const parsedResponse = await new JsonRestRequest(jwt).get(url);
		const dto = parsedResponse.body as ObjectiveDto;
		const item = new ObjectiveResponseModelConverter().toModel(dto);
		return { item, hypermediaLinks: extractHypermediaResponse(parsedResponse.headers) };
	}

	public async search(filter?: FilterCriteria<ObjectiveModel>): Promise<HypermediaEntityResponse<ObjectiveModel>> {
		const url = buildApiEndpointUrl(
			this.apiBaseUrl,
			'okr/objective/objective',
			new Map<string, UrlPathParameter>([]),
			new Map<string, UrlQueryParameter>([['filter', filter]])
		);
		const jwt = await this.accessTokenLoader();
		const parsedResponse = await new JsonRestRequest(jwt).get(url);
		const dto = parsedResponse.body as ObjectiveDto;
		const item = new ObjectiveResponseModelConverter().toModel(dto);
		return { item, hypermediaLinks: extractHypermediaResponse(parsedResponse.headers) };
	}

	public async head(id: ModelPrimaryKey): Promise<HypermediaResponse> {
		const url = buildApiEndpointUrl(
			this.apiBaseUrl,
			'okr/objective/objective',
			new Map<string, UrlPathParameter>([['id', id]]),
			new Map<string, UrlQueryParameter>([])
		);
		const jwt = await this.accessTokenLoader();
		const parsedResponse = await new JsonRestRequest(jwt).head(url);
		return extractHypermediaResponse(parsedResponse.headers);
	}

	public async create(createModel: ObjectiveCreateModel): Promise<HypermediaEntityResponse<ObjectiveModel>> {
		const url = buildApiEndpointUrl(
			this.apiBaseUrl,
			'okr/objective/objective',
			new Map<string, UrlPathParameter>([]),
			new Map<string, UrlQueryParameter>([])
		);
		const jwt = await this.accessTokenLoader();
		const requestDto = new ObjectiveCreateRequestModelConverter().toDto(createModel);
		const parsedResponse = await new JsonRestRequest(jwt).post(url, requestDto);
		const dto = parsedResponse.body as ObjectiveDto;
		const item = new ObjectiveResponseModelConverter().toModel(dto);
		return { item, hypermediaLinks: extractHypermediaResponse(parsedResponse.headers) };
	}

	public async mutate(id: ModelPrimaryKey, modelMutation: ObjectiveMutateModel): Promise<HypermediaEntityResponse<ObjectiveModel>> {
		const url = buildApiEndpointUrl(
			this.apiBaseUrl,
			'okr/objective/objective',
			new Map<string, UrlPathParameter>([['id', id]]),
			new Map<string, UrlQueryParameter>([])
		);
		const jwt = await this.accessTokenLoader();
		const requestDto = new ObjectiveMutateRequestModelConverter().toDto(modelMutation);
		const parsedResponse = await new JsonRestRequest(jwt).patch(url, requestDto);
		const dto = parsedResponse.body as ObjectiveDto;
		const item = new ObjectiveResponseModelConverter().toModel(dto);
		return { item, hypermediaLinks: extractHypermediaResponse(parsedResponse.headers) };
	}

	public async delete(id: ModelPrimaryKey): Promise<DeleteResponse> {
		const url = buildApiEndpointUrl(
			this.apiBaseUrl,
			'okr/objective/objective',
			new Map<string, UrlPathParameter>([['id', id]]),
			new Map<string, UrlQueryParameter>([])
		);
		const jwt = await this.accessTokenLoader();
		const parsedResponse = await new JsonRestRequest(jwt).delete(url);
		return parsedResponse.body as DeleteResponse;
	}

	public async setStatus(id: ModelPrimaryKey, status: StatusRequestModel): Promise<HypermediaEntityResponse<ObjectiveModel>> {
		const url = buildApiEndpointUrl(
			this.apiBaseUrl,
			'okr/objective/objective/{id}/set-status',
			new Map<string, UrlPathParameter>([['id', id]]),
			new Map<string, UrlQueryParameter>([])
		);
		const jwt = await this.accessTokenLoader();
		const requestDto = new StatusRequestRequestModelConverter().toDto(status);
		const parsedResponse = await new JsonRestRequest(jwt).put(url, requestDto);
		const dto = parsedResponse.body as ObjectiveDto;
		const item = new ObjectiveResponseModelConverter().toModel(dto);
		return { item, hypermediaLinks: extractHypermediaResponse(parsedResponse.headers) };
	}

	public async setProgress(id: ModelPrimaryKey, progress: ProgressRequestModel): Promise<HypermediaEntityResponse<ObjectiveModel>> {
		const url = buildApiEndpointUrl(
			this.apiBaseUrl,
			'okr/objective/objective/{id}/set-progress',
			new Map<string, UrlPathParameter>([['id', id]]),
			new Map<string, UrlQueryParameter>([])
		);
		const jwt = await this.accessTokenLoader();
		const requestDto = new ProgressRequestRequestModelConverter().toDto(progress);
		const parsedResponse = await new JsonRestRequest(jwt).put(url, requestDto);
		const dto = parsedResponse.body as ObjectiveDto;
		const item = new ObjectiveResponseModelConverter().toModel(dto);
		return { item, hypermediaLinks: extractHypermediaResponse(parsedResponse.headers) };
	}

	public async setResponsibles(
		id: ModelPrimaryKey,
		responsibles: ResponsiblesRequestModel
	): Promise<HypermediaEntityResponse<ObjectiveModel>> {
		const url = buildApiEndpointUrl(
			this.apiBaseUrl,
			'okr/objective/objective/{id}/set-responsibles',
			new Map<string, UrlPathParameter>([['id', id]]),
			new Map<string, UrlQueryParameter>([])
		);
		const jwt = await this.accessTokenLoader();
		const requestDto = new ResponsiblesRequestRequestModelConverter().toDto(responsibles);
		const parsedResponse = await new JsonRestRequest(jwt).put(url, requestDto);
		const dto = parsedResponse.body as ObjectiveDto;
		const item = new ObjectiveResponseModelConverter().toModel(dto);
		return { item, hypermediaLinks: extractHypermediaResponse(parsedResponse.headers) };
	}

	public async addFollower(id: ModelPrimaryKey, follower: FollowerRequestModel): Promise<HypermediaEntityResponse<ObjectiveModel>> {
		const url = buildApiEndpointUrl(
			this.apiBaseUrl,
			'okr/objective/objective/{id}/add-follower',
			new Map<string, UrlPathParameter>([['id', id]]),
			new Map<string, UrlQueryParameter>([])
		);
		const jwt = await this.accessTokenLoader();
		const requestDto = new FollowerRequestRequestModelConverter().toDto(follower);
		const parsedResponse = await new JsonRestRequest(jwt).put(url, requestDto);
		const dto = parsedResponse.body as ObjectiveDto;
		const item = new ObjectiveResponseModelConverter().toModel(dto);
		return { item, hypermediaLinks: extractHypermediaResponse(parsedResponse.headers) };
	}

	public async removeFollower(id: ModelPrimaryKey, follower: FollowerRequestModel): Promise<HypermediaEntityResponse<ObjectiveModel>> {
		const url = buildApiEndpointUrl(
			this.apiBaseUrl,
			'okr/objective/objective/{id}/remove-follower',
			new Map<string, UrlPathParameter>([['id', id]]),
			new Map<string, UrlQueryParameter>([])
		);
		const jwt = await this.accessTokenLoader();
		const requestDto = new FollowerRequestRequestModelConverter().toDto(follower);
		const parsedResponse = await new JsonRestRequest(jwt).put(url, requestDto);
		const dto = parsedResponse.body as ObjectiveDto;
		const item = new ObjectiveResponseModelConverter().toModel(dto);
		return { item, hypermediaLinks: extractHypermediaResponse(parsedResponse.headers) };
	}

	public async setFollowers(id: ModelPrimaryKey, followers: FollowersRequestModel): Promise<HypermediaEntityResponse<ObjectiveModel>> {
		const url = buildApiEndpointUrl(
			this.apiBaseUrl,
			'okr/objective/objective/{id}/set-followers',
			new Map<string, UrlPathParameter>([['id', id]]),
			new Map<string, UrlQueryParameter>([])
		);
		const jwt = await this.accessTokenLoader();
		const requestDto = new FollowersRequestRequestModelConverter().toDto(followers);
		const parsedResponse = await new JsonRestRequest(jwt).put(url, requestDto);
		const dto = parsedResponse.body as ObjectiveDto;
		const item = new ObjectiveResponseModelConverter().toModel(dto);
		return { item, hypermediaLinks: extractHypermediaResponse(parsedResponse.headers) };
	}
}
