/* eslint-disable jsdoc/require-param, jsdoc/require-returns */
/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
import { AbstractRequestModelConverter, NoopModelConverter } from '@chroma-x/frontend/core/api-integration';
import { FollowersRequestModel } from '@chroma-x/frontend/domain/okr/domain-model';

import { FollowersRequestDto } from '../../../../../dto';

export class FollowersRequestRequestModelConverter extends AbstractRequestModelConverter<FollowersRequestModel, FollowersRequestDto> {
	public toDto(model: FollowersRequestModel): FollowersRequestDto {
		return {
			followers: new NoopModelConverter().toArrayOfDto(model.followers)
		};
	}
}
