/* eslint-disable jsdoc/require-param, jsdoc/require-returns */
/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
export enum InitiativeHistoryServicePermission {
	OKR_INITIATIVE_HISTORY_READ = 'okr:initiative-history:read'
}

export const initiativeHistoryServicePermissions = [InitiativeHistoryServicePermission.OKR_INITIATIVE_HISTORY_READ];
