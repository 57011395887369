/* eslint-disable jsdoc/require-param, jsdoc/require-returns */
/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
export enum KeyResultServicePermission {
	OKR_KEY_RESULT_LIST = 'okr:key-result:list',
	OKR_KEY_RESULT_READ = 'okr:key-result:read',
	OKR_KEY_RESULT_CREATE = 'okr:key-result:create',
	OKR_KEY_RESULT_MUTATE = 'okr:key-result:mutate',
	OKR_KEY_RESULT_UPDATE = 'okr:key-result:update',
	OKR_KEY_RESULT_DELETE = 'okr:key-result:delete'
}

export const keyResultServicePermissions = [
	KeyResultServicePermission.OKR_KEY_RESULT_LIST,
	KeyResultServicePermission.OKR_KEY_RESULT_READ,
	KeyResultServicePermission.OKR_KEY_RESULT_CREATE,
	KeyResultServicePermission.OKR_KEY_RESULT_MUTATE,
	KeyResultServicePermission.OKR_KEY_RESULT_UPDATE,
	KeyResultServicePermission.OKR_KEY_RESULT_DELETE
];
