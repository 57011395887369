/* eslint-disable jsdoc/require-param, jsdoc/require-returns */
/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */

export enum ObjectiveProgressMode {
	KEY_RESULTS = 'KEY_RESULTS',
	MANUAL = 'MANUAL'
}
