/* eslint-disable jsdoc/require-param, jsdoc/require-returns */
/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */

export enum ObjectiveTimeframe {
	LONG_TERM = 'LONG_TERM',
	MID_TERM = 'MID_TERM'
}
