/* eslint-disable jsdoc/require-param, jsdoc/require-returns */
/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/okr/api-spec/tspconfig.yml
 */
import { ObjectiveDto } from '@chroma-x/frontend/domain/okr/api-integration';

import { keyResultBasedProgressObjectiveDtoMockData } from './key-result-based-progress-objective-dto-mock-data';

export const objectiveDtoMockData: ObjectiveDto = keyResultBasedProgressObjectiveDtoMockData;
